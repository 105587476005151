@import "@volt/component-library/dist/volt-component-library/volt-component-library.scss";

.detailsRightHeading {
  @include font-style(headline-4);
  font-size: 30px;
  margin-bottom: 16px;

  @media (max-width: 600px) {
    margin-bottom: 22px;
  }
}

.detailsRightParagraph {
  @include font-style(body-1);
  font-feature-settings: "clig" off, "liga" off;

  @media (max-width: 600px) {
    font-weight: 400;
    line-height: 24px;
  }
}

.detailsLearnMoreBtn {
  @include font-style(headline-5);
  padding-right: 10px;
}

.factAndBenefitsHeading {
  @include font-style(headline-6);
  padding: 10px 0 26px;

  @media (max-width: 600px) {
    font-size: 15px;
  }
}
