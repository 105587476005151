@import "@volt/component-library/dist/volt-component-library/volt-component-library.scss";

.subFooterHeader {
  @include font-style(headline-6);
  color: var(--dark-ui-primary, #fff);
  padding-bottom: 18px;
}

.subFooterBody {
  @include font-style(body-1);
  color: var(--dark-ui-primary, #fff);
  font-weight: 400;
  padding-bottom: 18px;

  @media (max-width: 768px) {
    font-size: 17px;
  }
}

.subFooterLists {
  @include font-style(body-1);
  color: var(--dark-ui-primary, #fff);
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 16px;
  text-decoration: none;
  cursor: pointer;
}

.addressText {
  @include font-style(body-1);
  color: var(--dark-ui-primary, #fff);
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 17px;
  }
}
