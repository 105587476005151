@import "@volt/component-library/dist/volt-component-library/volt-component-library.scss";

.iconLabelHeading {
  @include font-style(headline-3);
  color: var(--dark-ui-primary, #fff);
  font-weight: 400;
  padding-left: 16px;

  @media (max-width: 1280px) {
    font-size: 20px;
  }

  @media (max-width: 600px) {
    font-size: 13px;
  }
}
