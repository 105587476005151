@import "@volt/component-library/dist/volt-component-library/volt-component-library.scss";

.supportHeader {
  @include font-style(headline-2);
  color: $white;
  font-weight: 400;
  padding-bottom: 8px;

  @media (max-width: 600px) {
    font-size: 25px;
    font-weight: 700;
    padding-bottom: 0;
  }
}

.supportBody {
  @include font-style(body-1);
  color: $white;
  padding-bottom: 40px;
}
