@import "@volt/component-library/dist/volt-component-library/volt-component-library.scss";

.signInHeader {
  @include font-style(headline-1);
  color: $white;

  @media (max-width: 768px) {
    font-weight: 400;
  }
}
