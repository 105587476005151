.logoImg {
  width: 90px !important;
  margin-right: 20px;
}

.railconnectTitle {
  color: "#ED2027";
}
.support {
  color: black;
  align-self: center;
}

.signInBtn {
  color: "#ED2027";
}

.box {
  position: sticky;
  z-index: 120;
  top: 0;
}

.search {
  background-color: black;
}
